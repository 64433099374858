import { useEffect } from 'react';
import { isBrowser } from 'react-use/lib/misc/util';
import { Colors } from '../../../../play-core/styles/Colors';
import { defaultManifestName, imagesBasePath, resourceBasePath } from '../../../../play-core/utils/base-path';
import { usePlayConfig } from '../../config/PlayConfigContext';
// We only want to index the pages on production and when requested via CDN.
const noIndex = ({
  serverEnvironment,
  isRequestedViaCDN
}) => serverEnvironment !== 'production' || !isRequestedViaCDN;
export const getGlobalRemixMeta = ({
  config,
  i18n,
  url
}) => {
  const {
    businessUnit,
    meta,
    hostName,
    baseUrl
  } = config;
  const siteName = `Play ${businessUnit.toUpperCase()}`;
  const publicUrl = getPublicUrl(url, hostName, baseUrl);
  const metaData = [{
    title: siteName
  }, {
    tagName: 'link',
    rel: 'icon',
    href: `${resourceBasePath}/favicon-${businessUnit}.ico`
  },
  // Open Graph Tags
  {
    property: 'og:title',
    content: siteName
  }, {
    property: 'og:site_name',
    content: siteName
  }, {
    property: 'og:description',
    content: i18n.t('global.defaultMetaDescription')
  }, {
    property: 'og:url',
    content: publicUrl
  },
  // Metadata Reference
  {
    property: 'al:ios:app_name',
    content: meta.appName
  }, {
    property: 'al:ios:app_store_id',
    content: meta.iosAppStoreId
  }, {
    property: 'al:android:app_name',
    content: meta.appName
  },
  // Meta Tags
  {
    name: 'description',
    content: i18n.t('global.defaultMetaDescription')
  }, {
    name: 'theme-color',
    content: Colors.GREY_1a1a1a
  }, {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
  }, {
    name: 'google-site-verification',
    content: '5vsQYZgHQKDglPft8rn8G1KnTc68TLw2EO6R2IF3pLE'
  }, {
    name: 'robots',
    content: 'max-image-preview:large'
  },
  // Splashscreens
  {
    tagName: 'link',
    rel: 'apple-touch-startup-image',
    href: `${imagesBasePath}/splashscreens/${businessUnit}/iphone5_splash-${businessUnit}.png`,
    media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)'
  }, {
    tagName: 'link',
    rel: 'apple-touch-startup-image',
    href: `${imagesBasePath}/splashscreens/${businessUnit}/iphone6_splash-${businessUnit}.png`,
    media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)'
  }, {
    tagName: 'link',
    rel: 'apple-touch-startup-image',
    href: `${imagesBasePath}/splashscreens/${businessUnit}/iphoneplus_splash-${businessUnit}.png`,
    media: '(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)'
  }, {
    tagName: 'link',
    rel: 'apple-touch-startup-image',
    href: `${imagesBasePath}/splashscreens/${businessUnit}/iphonex_splash-${businessUnit}.png`,
    media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)'
  }, {
    tagName: 'link',
    rel: 'apple-touch-startup-image',
    href: `${imagesBasePath}/splashscreens/${businessUnit}/iphonexr_splash-${businessUnit}.png`,
    media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)'
  }, {
    tagName: 'link',
    rel: 'apple-touch-startup-image',
    href: `${imagesBasePath}/splashscreens/${businessUnit}/iphonexsmax_splash-${businessUnit}.png`,
    media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)'
  }, {
    tagName: 'link',
    rel: 'apple-touch-startup-image',
    href: `${imagesBasePath}/splashscreens/${businessUnit}/ipad_splash-${businessUnit}.png`,
    media: '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)'
  }, {
    tagName: 'link',
    rel: 'apple-touch-startup-image',
    href: `${imagesBasePath}/splashscreens/${businessUnit}/ipadpro1_splash-${businessUnit}.png`,
    media: '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)'
  }, {
    tagName: 'link',
    rel: 'apple-touch-startup-image',
    href: `${imagesBasePath}/splashscreens/${businessUnit}/ipadpro3_splash-${businessUnit}.png`,
    media: '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)'
  }, {
    tagName: 'link',
    rel: 'apple-touch-startup-image',
    href: `${imagesBasePath}/splashscreens/${businessUnit}/ipadpro2_splash-${businessUnit}.png`,
    media: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)'
  },
  // Default webmnifest
  {
    tagName: 'link',
    rel: 'manifest',
    href: `${resourceBasePath}/${businessUnit}/${defaultManifestName}`,
    crossOrigin: 'use-credentials'
  }, {
    tagName: 'link',
    rel: 'apple-touch-icon',
    href: `${imagesBasePath}/appIcons/${businessUnit}/play-${businessUnit}_192x192.png`,
    media: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)'
  }, {
    name: 'al:ios:url',
    content: publicUrl.replace(/^https?/, meta.appSchemeUrl)
  }, {
    name: 'al:android:url',
    content: publicUrl.replace(/^https?/, meta.appSchemeUrl)
  }];
  if (noIndex(config)) metaData.push({
    name: 'robots',
    content: 'noindex, nofollow'
  });
  return metaData;
};

// Converts the request URL (incoming URL on Heroku) to the public URL that is visible to the user.
export const getPublicUrl = (requestUrl, hostName, baseUrl) => {
  const url = new URL(requestUrl);
  const index = url.pathname.indexOf(baseUrl);
  url.pathname = index !== -1 ? url.pathname.slice(index) : '';
  url.host = hostName;
  url.port = '';
  url.protocol = 'https:';
  return url.toString();
};
export const getRemixMeta = ({
  title,
  description,
  type = 'website',
  image,
  imageWidth,
  imageHeight,
  allowIndexing = true,
  config,
  'script:ld+json': ldJson
}) => {
  const {
    businessUnit
  } = config;
  const pageTitle = getPageTitle(businessUnit, title);
  const metaData = [{
    title: pageTitle
  },
  // Open Graph Tags
  {
    property: 'og:type',
    content: type
  }, {
    property: 'og:title',
    content: pageTitle
  }, {
    property: 'og:image',
    content: image
  }];
  if (description) {
    metaData.push({
      property: 'og:description',
      content: description
    });
    metaData.push({
      name: 'description',
      content: description
    });
  }
  if (imageWidth) {
    metaData.push({
      property: 'og:image:width',
      content: imageWidth.toString()
    });
    if (imageWidth > 400) metaData.push({
      name: 'twitter:card',
      content: 'summary_large_image'
    });
  }
  if (imageHeight) metaData.push({
    property: 'og:image:height',
    content: imageHeight.toString()
  });
  if (ldJson) {
    (Array.isArray(ldJson) ? ldJson : [ldJson]).map(json => metaData.push({
      'script:ld+json': json
    }));
  }
  if (noIndex(config) || !allowIndexing) metaData.push({
    name: 'robots',
    content: 'noindex, nofollow'
  });
  return metaData;
};
const getPageTitle = (businessUnit, title) => {
  const siteName = `Play ${businessUnit.toUpperCase()}`;
  return title ? `${title} - ${siteName}` : siteName;
};
export const usePageTitle = title => {
  const {
    businessUnit
  } = usePlayConfig();
  useEffect(() => {
    if (isBrowser) {
      window.document.title = getPageTitle(businessUnit, title);
    }
  }, [title, businessUnit]);
};